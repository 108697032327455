// HEAD DATA
export const headData = {
  title: 'Mana Taheri', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'learning experience designer, innovation consultant and educator', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Mana Taheri',
  subtitle: "I'm a learning experience designer, innovation consultant and educator.",
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'mtaheri_header.jpg',
  paragraphOne: 'I combine my experience in human-centered design, visualization, and instructional design to support teams with their innovation projects. I am passionate about the intersection of design and social justice and the role and responsibility of design in creating a more equitable world.',
  paragraphTwo: 'Areas of expertise:',
  paragraphThree: `
  <ul>
  <li>Design Thinking</li>
  <li>Design Research</li>
  <li>MOOC Design</li>
  <li>Design, Diversity & Inclusion</li>
  <li>Visual Storytelling & Graphic Recording</li>
  </ul>
  `,
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [{
    img: '',
    title: 'Wikimedia Foundation (UNLOCK Accelerator)',
    info: '',
    date: 'May 2021 - present',
    url: 'https://www.wikimedia.de/unlock/program/',
    cta: 'more',
  },
  {
    img: '',
    title: 'Initiative Selbständiger Migrantinnen e.V.',
    date: 'August 2021 - present',
    info: '',
    url: 'https://isi-ev.de/kurse-selbstaendige/',
    cta: 'more',
  },
  {
    img: '',
    title: 'MitOst e.V.: Civil Society Exchange',
    date: 'August 2020',
    info: '',
    url: 'https://civilsocietyexchange.org/csepp-first-phase/',
    cta: 'more',
  },
  {
    img: '',
    title: 'HPI Academy: BI Norwegian business school',
    date: 'December 2020',
    info: '',
    url: 'https://mobile.fdsm.fudan.edu.cn/cs/nwmba/cn/xyyl.m',
    cta: 'more',
  },
  {
    img: '',
    title: 'HPI d-school: Melton Foundation',
    date: 'March 2017 - Sept 2020',
    info: '',
    url: 'https://meltonfoundation.org/global-events/new-fellow-orientation/2017-berlin/',
    cta: 'more',
  },
];

// TALKS DATA
export const talksData = [{
  title: 'Wikimedia Foundation (UNLOCK Accelerator)',
  client: 'CLIENT NAME',
  date: 'May 2021',
}];

// RESEARCH DATA
export const researchData = [{
    title: 'The Impact of the Socio-Cultural Context on Design Thinking Education',
    author: 'Mana Taheri',
    year: 'forthcoming',
    publication: 'Doctoral Thesis',
    pages: '',
    organization: 'University Potsdam',
    url: '',
  },
  {
    title: 'Towards a Culturally Responsive Design Thinking Education',
    author: 'Mana Taheri',
    year: 'In Press',
    publication: 'Design Thinking in der Bildung: Innovation kann man lernen',
    pages: '',
    organization: 'Wiley-VCH GmbH & Co.',
    url: '',
  },
  {
    title: 'Designing Culturally Inclusive MOOCs',
    author: 'Mana Taheri, Katharina Hölzle, Christoph Meinel',
    year: '2020',
    publication: 'CSEDU 2019: Computer Supported Education',
    pages: 'pp 524-537',
    organization: 'Springer',
    url: 'https://link.springer.com/chapter/10.1007/978-3-030-58459-7_25',
  },
  {
    title: 'Towards Culturally Inclusive MOOCs: A Design-based Approach',
    author: 'Mana Taheri, Katharina Hölzle, Christoph Meinel',
    year: '2019',
    publication: 'Proceedings from the 11th International Conference on Computer Supported Education (CSEDU 2019)',
    pages: '',
    organization: '',
    url: '',
  },
  {
    title: 'The DT MOOC Prototype: Towards Teaching Design Thinking at Scale',
    author: 'Mana Taheri, Lena Mayer, Karen von Schmieden, Christoph Meinel',
    year: '2018',
    publication: 'Design Thinking Research',
    pages: 'pp. 217-237',
    organization: 'Springer, Cham',
    url: '',
  },
  {
    title: 'Design Thinking at Scale: A Report on Best Practices of Online Courses',
    author: 'Mana Taheri, Thomas Unterholzer, Katharina Hölzle, Christoph Meinel',
    year: '2016',
    publication: 'ISPIM Innovation Symposium',
    pages: '',
    organization: 'The International Society for Professional Innovation Management (ISPIM)',
    url: '',
  },
  {
    title: 'An educational perspective on design thinking learning outcomes',
    author: 'Mana Taheri, Thomas Unterholzer, Katharina Hölzle, Christoph Meinel',
    year: '2016',
    publication: 'ISPIM Innovation Symposium',
    pages: '',
    organization: 'The International Society for Professional Innovation Management (ISPIM)',
    url: '',
    link_text: '',
  },
  {
    title: 'Pedagogical Evaluation of the Design Thinking MOOCs',
    author: 'Mana Taheri, Christoph Meinel',
    year: '2015',
    publication: 'Proceedings from the 3rd international conference for design education researchers',
    pages: 'pp. 469-481',
    organization: '',
    url: 'https://dl.designresearchsociety.org/cgi/viewcontent.cgi?article=1042&context=conference-volumes#page=493',
    link_text: 'download pdf',
  },



  {
    title: 'Razors for Arctic VIP Travelers: Using Warm-Up Games in MOOCs',
    author: 'Karen von Schmieden, Lena Mayer, Mana Taheri, Hanadi Traifeh, Christoph Meinel',
    year: '2021',
    publication: 'Understanding Innovation',
    pages: 'pp. 161-174',
    organization: 'Springer',
    url: '',
  },
  {
    title: 'Design Thinking Navigator: Kartenset zur kreativen Projektarbeit',
    author: 'Lena Mayer, Isabell Osann, Caroline Szymanski, Mana Taheri',
    year: '2020',
    publication: 'Understanding Innovation',
    pages: '',
    organization: 'Hanser',
    url: 'https://www.projektmagazin.de/projektmanagement-buecher/design-thinking-navigator',
  },
  {
    title: 'Designing a Synthesis MOOC: Lessons from Frameworks, Experiments and Learner Paths',
    author: 'Lena Mayer, Karen von Schmieden, Mana Taheri, Christoph Meinel',
    year: '2020',
    publication: 'Design Thinking Research',
    pages: '35 - 47',
    organization: 'Springer',
    url: '',
  },
  {
    title: 'Creative Skills on Demand: Adaptive Digital Education Formats for Organizational Learning Environments',
    author: 'Lena Mayer, Karen von Schmieden, Mana Taheri, Christoph Meinel',
    year: '2019',
    publication: "HI'19 Conference on Computational and Cognitive Models of Creative Design",
    pages: '',
    organization: '',
    url: '',
  },
  {
    title: 'Iterative Course Design in MOOCs: Evaluating a ProtoMOOC',
    author: 'Karen von Schmieden, Lena Mayer, Mana Taheri, Christoph Meinel',
    year: '2019',
    publication: 'Proceedings of the Design Society: International Conference on Engineering Design',
    pages: 'pp 539 - 548',
    organization: 'Cambridge University Press',
    url: '',
  },
  {
    title: 'An Iterative Approach to Online Course Design: Improving a Design Research MOOC',
    author: 'Karen von Schmieden, Lena Mayer, Mana Taheri, Christoph Meinel',
    year: '2019',
    publication: 'Design Thinking Research',
    pages: 'pp 99 - 112',
    organization: 'Springer',
    url: '',
  },
  {
    title: 'Supporting Design Thinking Education',
    author: 'Lena Mayer, Karen von Schmieden, Mana Taheri, Christoph Meinel',
    year: '2017',
    publication: 'Organizations With Digital Learning Units – A Testing Set-Up',
    pages: '',
    organization: '18th International Continuous Innovation Network Conference',
    url: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [{
      name: 'envelope',
      url: 'mailto:hello@manataher.com',
    },
    {
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/manataheri/',
    },
  ],
};